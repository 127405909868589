<script lang="ts">
  import { v4 as uuidv4 } from "uuid"
  import { actionStore } from "../../actions/ActionStore"
  import { type ActionState } from "../../actions/types"
  import { advisorInfoStore } from "../../stores"
  import Loader from "../../icons/Loader.svelte"
  import Success from "../../icons/Success.svelte"
  import Cross from "../../icons/Cross.svelte"
  import Button from "../Button.svelte"

  import { appAnalyticsStore } from "../../analytics/appAnalytics"
  import type { Recommendation } from "../../app/toPage"
  import type {
    ActionTriggered,
    InternalAnalyticsProduct,
    RecommendationContext,
  } from "../../analytics/internal/types"
  import type { ComponentType, SvelteComponent } from "svelte"

  export let actionIdentifier: string
  export let testId: string
  export let icon: ComponentType<SvelteComponent>
  export let isWide: boolean = false
  export let variant: "primary" | "secondary"
  export let context:
    | {
        recommendationContext: RecommendationContext
        recommendation: Recommendation
        location: ActionTriggered["location"]
        triggerLocation: ActionTriggered["triggerLocation"]
      }
    | {
        recommendations: Recommendation[]
        location: ActionTriggered["location"]
        triggerLocation: ActionTriggered["triggerLocation"]
      }

  /** This callback is called when the button is clicked */
  export let analyticsCallback: (() => void) | undefined = undefined

  $: status = $actionStore[triggerId] || "IDLE"
  $: isLoading = status === "PENDING" || status === "ACKNOWLEDGED"

  const triggerId = uuidv4()

  const onClick = (e: KeyboardEvent | MouseEvent) => {
    e.preventDefault()

    function toInternalProduct(
      recommendation: Recommendation
    ): InternalAnalyticsProduct {
      return {
        id: recommendation.productId,
        productId: recommendation.clientProductId,
        name: recommendation.productName,
        url: recommendation.productUrl,
        price: recommendation.specification.price,
        quantity: 1,
      }
    }

    const [recommendationContext, products] =
      "recommendation" in context
        ? [
            {
              ...context.recommendationContext,
              product: toInternalProduct(context.recommendation),
            },
            [context.recommendation],
          ]
        : [undefined, context.recommendations]

    $appAnalyticsStore.customActionEvent(
      actionIdentifier,
      recommendationContext,
      context.location,
      context.triggerLocation,
      products.map(toInternalProduct)
    )

    if (analyticsCallback) analyticsCallback()

    actionStore.trigger(triggerId, {
      advisorId: $advisorInfoStore.advisorId,
      advisorName: $advisorInfoStore.advisorName,
      actionId: actionIdentifier,
      products: products.map((recommendation) => {
        return {
          id: recommendation.clientProductId,
          name: recommendation.productName,
          quantity: 1,
        }
      }),
    })
  }
</script>

<Button
  isFullWidth={isWide}
  {variant}
  onClick={isLoading ? () => {} : onClick}
  {testId}
>
  {#if status === "PENDING" || status === "ACKNOWLEDGED"}
    <Loader />
  {:else if status === "SUCCESS"}
    <Success />
  {:else if status === "FAILED"}
    <Cross />
  {:else}
    <svelte:component this={icon} />
  {/if}
  <slot />
</Button>
