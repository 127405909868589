<script lang="ts">
  import ContextMenuHandler from "./contextMenuHandler"

  export let onClick = (event: KeyboardEvent | MouseEvent) => {}
  export let disabled: boolean = false
  export let isSmall: boolean = false
  export let isPrimary: boolean = false
  export let isCircle: boolean = false
  export let isText: boolean = false
  export let isFullWidth: boolean = false
  export let centerAlign: boolean = false
  export let noHorizontalPadding: boolean = false
  export let hasShadow: boolean = false
  export let className: string = ""
  export let testId: string | undefined = undefined
  export let id: string = ""
  export let onContextMenu: () => void = () => {}
</script>

<button
  class={className}
  on:click={onClick}
  {disabled}
  class:isSmall
  class:isPrimary
  class:isCircle
  class:isFullWidth
  class:isText
  class:hasShadow
  class:noHorizontalPadding
  class:centerAlign
  data-test-id={testId}
  use:ContextMenuHandler={onContextMenu}
  {id}
>
  <slot />
</button>

<style>
  button {
    width: auto;
    padding: clamp(12px, var(--aiden-button-padding-v), 16px)
      var(--aiden-button-padding-h);
    display: inline-flex;
    align-items: center;
    font-family: var(--aiden-font-family);
    font-size: 1em;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    text-wrap: balance;
    overflow-wrap: break-word;
    hyphens: manual;
    gap: 0.5em;
    line-height: 1;
    color: var(--aiden-text-color);
    background: white;
    border: 2px solid var(--aiden-shade3-color);
    border-radius: var(--aiden-border-radius);
    white-space: nowrap;
    -webkit-font-smoothing: var(--aiden-primary-font-smoothing-webkit);
    -moz-osx-font-smoothing: var(--aiden-primary-font-smoothing-moz);
    transition:
      background 0.2s ease-out,
      border-color 0.2s ease-out,
      filter 0.2s ease-out,
      box-shadow 0.2s ease-out,
      color 0.2s ease-out;
    text-align: center;
    justify-content: center;
    align-self: center;
  }
  button.isSmall {
    font-size: 0.75em;
    padding: var(--aiden-button-padding-v-small)
      var(--aiden-button-padding-h-small);
  }
  button.isFullWidth {
    display: inline-flex;
    width: 100%;
  }

  button.centerAlign {
    display: flex;
    margin: 3rem auto;
  }

  button.hasShadow {
    box-shadow: var(--aiden-box-shadow-level-2);
  }

  button.hasShadow:hover,
  button.hasShadow:focus {
    box-shadow: var(--aiden-box-shadow-level-1);
  }

  button:hover {
    background-color: var(--aiden-shade3-color);
  }

  button:focus-visible {
    border-color: var(--aiden-secondary-color);
  }

  button :global(svg) {
    width: 1em;
    height: 1em;
    pointer-events: none;
  }
  button :global(svg) {
    margin-right: 0.5em;
  }
  button :global(svg):last-child {
    margin-right: 0;
    margin-left: 0.5em;
  }
  button.isCircle :global(svg):last-child {
    margin-left: 0;
    margin: 0.25em;
  }
  button.isPrimary {
    color: var(--aiden-primary-text-color);
    border-color: var(--aiden-primary-color);
    -webkit-font-smoothing: var(--aiden-primary-font-smoothing-webkit);
    -moz-osx-font-smoothing: var(--aiden-primary-font-smoothing-moz);
    background: var(--aiden-primary-color);
  }

  button.isPrimary:not(:disabled):hover,
  button.isPrimary:focus {
    filter: brightness(var(--aiden-primary-hover-brightness));
  }

  button.isCircle {
    border-radius: 100%;
    padding: var(--aiden-button-padding-v);
    display: flex;
    flex-shrink: 0;
    place-content: center;
    align-content: center;
  }
  button.isCircle:focus-visible {
    border-color: var(--aiden-secondary-color);
  }

  button:disabled,
  button.isPrimary:disabled {
    pointer-events: none;
    box-shadow: none;
    background-color: var(--aiden-shade3-color);
    border-color: var(--aiden-shade3-color);
    color: var(--aiden-shade4-color);
  }

  button.isText,
  button.isSmall.isText {
    border-color: transparent;
    background: transparent;
    padding: 0.75rem;
  }
  button.isText:hover {
    background: none;
    text-decoration: underline;
  }

  button.noHorizontalPadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
</style>
