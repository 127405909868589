<script lang="ts">
  export let legend: string = ""
</script>

<!-- @component

This component is used to add more (invisible) information to a group of elements and is readable by screenreaders. -->

<fieldset class="unstyled">
  {#if legend}
    <legend class="screenreader-only">
      {legend}
    </legend>
  {/if}
  <slot></slot>
</fieldset>

<style>
  fieldset.unstyled {
    border: 0;
    margin: 0;
    padding: 0;
  }
</style>
