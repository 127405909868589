<script lang="ts">
  import Layout from "../ui/Layout.svelte"
  import Bounded from "../ui/Bounded.svelte"
  import TopHeader from "../ui/TopHeader.svelte"
  import Plane from "../ui/Plane.svelte"
  import DrilldownSelect from "../ui/DrilldownSelect.svelte"
  import FieldsetWithLegend from "../ui/FieldsetWithLegend.svelte"
  import { t } from "../lang/i18n"
</script>

<div class="aiden-screen-content">
  <Bounded>
    <TopHeader
      title="Drilldown question title"
      text="Drilldown question intro text. Ipsum Mattis Nibh Euismod Tortor"
    />
  </Bounded>
  <Bounded>
    <Plane>
      <FieldsetWithLegend legend={$t("manualDrilldownLegend")}>
        <Layout>
          <DrilldownSelect id="step-1" label="Level 1" value="" />
          <DrilldownSelect id="step-2" label="Level 2" value="" />
          <DrilldownSelect id="step-3" label="Level 3" value="" />
        </Layout>
      </FieldsetWithLegend>
    </Plane>
  </Bounded>
  <slot></slot>
</div>
