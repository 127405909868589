const start = 1734307200 // 16-12-2024
const end = 1735776000 // 02-01-2025
import styled from "styled-components"
import img from "../images/holidays.jpg"

const Banner = styled.div`
  background: #150c3c;
  color: #f1fbff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.5rem;
  text-wrap: pretty;
  background: url(${img});
  background-size: cover;
  background-position: center 25%;
  padding-top: 3.5rem;
  p {
    font-weight: 600;
    margin: 0 0 0.25rem 0;
  }
`

const HolidayNotice: React.FC<{}> = () => {
  const now = new Date().getTime() / 1000
  const show = now >= start && now <= end
  return show ? (
    <Banner>
      <p>
        Our support team will be unavailable from December 25 through January 1.
        If you have any questions, please get in touch with us beforehand! 💬✨
      </p>
      <p style={{ color: "#ffC200" }}>Happy Holidays!</p>
    </Banner>
  ) : null
}

export default HolidayNotice
