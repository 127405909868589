import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Vis mere",
  showLess: "Vis mindre",
  bestMatch: "Bedste match",
  view: "Udsigt",
  customAction: "",
  notFound: "404: Ikke fundet",
  reset: "Start forfra",
  close: "Lukke",
  noAdviceTitle:
    "Vi har i øjeblikket ingen produkter tilgængelige, der fuldstændigt matcher dine præferencer.",
  noAdviceMessage: "Du kan ændre dine præferencer, eller prøv igen senere.",
  prev: "Forrige",
  next: "Næste",
  backToAdvice: "Tilbage til råd",
  dontKnow: "Springe over",
  email: "Email addresse",
  send: "Sende",
  emailError: "Indtast en gyldig email addresse",
  readMore: "Læs mere",
  selectAnAnswer: "Vælg et svar",
  answerNotListed: "Mit svar er ikke opført",
  whyThisProductForMe: "Hvorfor dette råd?",
  alternative: "alternativ",
  alternatives: "alternativer",
  seeAlso: "Også se på",
  badMatchIcon: "Dårligt match",
  goodMatchIcon: "Godt match",
  perfectMatchIcon: "Perfekt match",
  singleAnswerQuestionLegend: "Vælg en mulighed og fortsæt.",
  multiAnswerQuestionLegend: "Vælg en eller flere muligheder.",
  multiAnswerQuestionWithNeutralLegend: "Vælg en eller flere muligheder, eller den nederste mulighed, hvis du ikke har nogen præference.",
  manualDrilldownLegend: "Vælg én mulighed. Hvis der er et opfølgende spørgsmål, vil det fremkomme efter valg af svar. Brug knappen 'Næste' for at fortsætte efter at have besvaret alle spørgsmål.",
  autoDrilldownLegend: "Vælg én mulighed. Hvis der er et opfølgende spørgsmål, vil det fremkomme efter valg af svar. Brug knappen 'Næste' for at fortsætte efter at have besvaret alle spørgsmål. Står dit svar ikke på listen? Vælg derefter 'Mit svar er ikke opført'.",
  loading: "Indlæser",
}

export default translations
