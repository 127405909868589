<script lang="ts">
  export let ContextMenuHandler = (
    _node: HTMLElement,
    _contextMenuFn: () => void
  ) => {}
  export let onContextMenu: () => void = () => {}
  export let onClick = (event: KeyboardEvent | MouseEvent) => {}

  export let id: string = ""
  export let disabled: boolean = false
  export let className: string = ""
  export let variant: "primary" | "secondary" | "tertiary" | "navigation" =
    "secondary"
  export let size: "small" | "regular" = "regular"
  export let alignment: "left" | "center" = "left"
  export let hasShadow: boolean = false
  export let hasNoHorizontalPadding: boolean = false
  export let isFullWidth: boolean = false
  export let previewHover: boolean = false
  export let testId: string | undefined = undefined
</script>

<button
  {id}
  {disabled}
  class={className}
  class:is-primary={variant === "primary"}
  class:is-secondary={variant === "secondary"}
  class:is-tertiary={variant === "tertiary"}
  class:is-navigation={variant === "navigation"}
  class:is-small={size === "small"}
  class:is-centered={alignment === "center"}
  class:hover-preview={previewHover}
  class:has-shadow={hasShadow}
  class:has-no-horizontal-padding={hasNoHorizontalPadding}
  class:is-full-width={isFullWidth}
  data-testid={testId}
  on:click={onClick}
  use:ContextMenuHandler={onContextMenu}
>
  <slot />
</button>

<style>
  button {
    cursor: pointer;
    width: auto;
    display: inline-flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    hyphens: manual;
    overflow-wrap: break-word;
    gap: 0.25rem;
    line-height: 1.25;
    max-width: 26rem;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    transition:
      background 0.2s ease-out,
      border-color 0.2s ease-out,
      color 0.2s ease-out;
    border-style: solid;

    & svg {
      flex-shrink: 0;
    }

    & span + svg {
      margin-left: 0.25rem;
    }

    & svg + span {
      margin-left: 0.25rem;
    }

    & span {
      text-wrap: balance;
      display: flex;
      align-items: center;
    }
  }

  /* button:focus-visible {
    outline: 2px solid blue;
  } */

  /* Variants */

  button.is-primary {
    background: var(--aiden-primary-button-background-color, #000);
    border-color: var(--aiden-primary-button-border-color, #000);
    border-width: var(--aiden-primary-button-border-width, 1px);
    padding-top: clamp(
      0.5rem,
      var(--aiden-primary-button-vertical-padding, 0.5rem),
      1rem
    );
    padding-bottom: clamp(
      0.5rem,
      var(--aiden-primary-button-vertical-padding, 0.5rem),
      1rem
    );

    padding-left: clamp(
      0.5rem,
      var(--aiden-primary-button-horizontal-padding, 1rem),
      1.625rem
    );
    padding-right: clamp(
      0.5rem,
      var(--aiden-primary-button-horizontal-padding, 1rem),
      1.625rem
    );
    color: var(--aiden-primary-button-color, #fff);
    font-weight: var(--aiden-primary-button-font-weight, 600);
    font-family: var(--aiden-primary-button-font, inherit);
    font-size: var(--aiden-primary-button-font-size, 0.875rem);
    text-transform: var(--aiden-primary-button-text-case, none);
    border-radius: var(--aiden-primary-button-border-radius, 0.75rem);
    -webkit-font-smoothing: var(--aiden-primary-font-smoothing-webkit);
    -moz-osx-font-smoothing: var(--aiden-primary-font-smoothing-moz);
    border-style: solid;
  }

  button.is-primary:not(:disabled):active,
  button.is-primary.hover-preview {
    background: var(--aiden-primary-button-background-color-hover, #333);
    color: var(--aiden-primary-button-color-hover, #fff);
    border-color: var(--aiden-primary-button-border-color-hover, #333);
  }

  @media (min-width: 768px) {
    button.is-primary:not(:disabled):focus,
    button.is-primary:not(:disabled):hover {
      background: var(--aiden-primary-button-background-color-hover, #333);
      color: var(--aiden-primary-button-color-hover, #fff);
      border-color: var(--aiden-primary-button-border-color-hover, #333);
    }
  }

  button.is-secondary {
    background: var(
      --aiden-secondary-button-background-color,
      rgba(255, 255, 255, 0)
    );
    border-color: var(--aiden-secondary-button-border-color, #000);
    border-width: var(--aiden-secondary-button-border-width, 1px);
    padding: clamp(
        0.5rem,
        var(--aiden-secondary-button-vertical-padding, 0.5rem),
        1rem
      )
      clamp(
        0.5rem,
        var(--aiden-secondary-button-horizontal-padding, 1rem),
        1.625rem
      );
    color: var(--aiden-secondary-button-color, #000);
    font-weight: var(--aiden-secondary-button-font-weight, 600);
    font-family: var(--aiden-secondary-button-font, inherit);
    font-size: var(--aiden-secondary-button-font-size, 0.875rem);
    text-transform: var(--aiden-secondary-button-text-case, none);
    border-radius: var(--aiden-secondary-button-border-radius, 0.75rem);
    border-style: solid;
  }

  button.is-secondary:not(:disabled):active,
  button.is-secondary.hover-preview {
    background: var(--aiden-secondary-button-background-color-hover, #000);
    color: var(--aiden-secondary-button-color-hover, #fff);
    border-color: var(--aiden-secondary-button-border-color-hover, #000);
  }

  @media (min-width: 768px) {
    button.is-secondary:not(:disabled):hover,
    button.is-secondary:not(:disabled):focus {
      background: var(--aiden-secondary-button-background-color-hover, #000);
      color: var(--aiden-secondary-button-color-hover, #fff);
      border-color: var(--aiden-secondary-button-border-color-hover, #000);
    }
  }

  button.is-tertiary {
    background: var(--aiden-tertiary-button-background-color, transparent);
    border-style: solid;
    border-color: var(--aiden-tertiary-button-border-color, transparent);
    border-width: var(--aiden-tertiary-button-border-width, 1px);
    padding: var(--aiden-tertiary-button-vertical-padding, 0.5rem)
      var(--aiden-tertiary-button-horizontal-padding, 1rem);
    color: var(--aiden-tertiary-button-color, #000);
    font-weight: var(--aiden-tertiary-button-font-weight, 600);
    font-family: var(--aiden-tertiary-button-font, inherit);
    font-size: var(--aiden-tertiary-button-font-size, 0.875rem);
    text-transform: var(--aiden-tertiary-button-text-case, none);
    border-radius: var(--aiden-tertiary-button-border-radius, 0.75rem);
  }

  button.is-tertiary:active {
    background: var(--aiden-tertiary-button-background-color-hover, #ebebeb);
    color: var(--aiden-tertiary-button-color-hover, #000);
    border-color: var(--aiden-tertiary-button-border-color-hover, #ebebeb);
  }

  @media (min-width: 768px) {
    button.is-tertiary:hover,
    button.is-tertiary:focus {
      background: var(--aiden-tertiary-button-background-color-hover, #ebebeb);
      color: var(--aiden-tertiary-button-color-hover, #000);
      border-color: var(--aiden-tertiary-button-border-color-hover, #ebebeb);
    }
  }

  button.is-navigation {
    background: var(--aiden-navigation-button-background-color, transparent);
    border-style: solid;
    border-color: var(--aiden-navigation-button-border-color, transparent);
    border-width: var(--aiden-navigation-button-border-width, 0px);

    padding: clamp(
        0.5rem,
        var(--aiden-navigation-button-vertical-padding, 0.5rem),
        1rem
      )
      clamp(
        0.5rem,
        var(--aiden-navigation-button-horizontal-padding, 1rem),
        1.625rem
      );
    padding: 0.5rem 0.5rem;
    color: var(--aiden-navigation-button-color, #000);
    font-weight: var(--aiden-navigation-button-font-weight, 600);
    font-family: var(--aiden-navigation-button-font, inherit);
    font-size: clamp(12px, 0.875rem, 16px);
    text-transform: var(--aiden-navigation-button-text-case, none);
    border-radius: var(--aiden-navigation-button-border-radius, 0.75rem);
    gap: 0.125rem;
  }

  button.is-navigation.hover-preview,
  button.is-navigation:active {
    background: var(--aiden-navigation-button-background-color-hover, #ebebeb);
    color: var(--aiden-navigation-button-color-hover, #000);
    border-color: var(--aiden-navigation-button-border-color-hover, #ebebeb);
  }

  @media (min-width: 768px) {
    button.is-navigation:hover {
      background: var(
        --aiden-navigation-button-background-color-hover,
        #ebebeb
      );
      color: var(--aiden-navigation-button-color-hover, #000);
      border-color: var(--aiden-navigation-button-border-color-hover, #ebebeb);
    }
  }

  /* Sizes */

  button.is-small {
    font-size: 0.75rem;
    padding: var(--aiden-button-padding-v-small)
      var(--aiden-button-padding-h-small);
  }

  /* Alignment */

  button.is-centered {
    display: flex;
    margin: 3rem auto;
  }

  /* Other options */

  button.has-shadow {
    box-shadow: var(--aiden-box-shadow-level-2);
  }

  button.has-shadow:hover,
  button.has-shadow:focus {
    box-shadow: var(--aiden-box-shadow-level-1);
  }

  button.has-no-horizontal-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  button.is-full-width {
    display: inline-flex;
    width: 100%;
  }

  button:disabled {
    pointer-events: none;
    cursor: not-allowed;
    /* box-shadow: none; */
    /* background-color: var(--aiden-shade3-color); */
    /* border-color: var(--aiden-shade3-color); */
    /* color: var(--aiden-shade4-color); */
    opacity: 0.45;
  }

  button :global(svg) {
    width: 1rem;
    height: 1rem;
    pointer-events: none;
  }
</style>
