<div class="button-group" {...$$restProps}>
  <slot />
</div>

<style>
  .button-group {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
  }
  .button-group > :global(*) {
    margin: 0;
  }
</style>
