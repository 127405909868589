<svg
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g fill="currentColor" fill-rule="nonzero">
      <g>
        <path
          d="M7.20665833,24 C6.54604798,24 5.98052549,23.765 5.51009084,23.295 C5.0396562,22.825 4.80443888,22.26 4.80443888,21.6 C4.80443888,20.94 5.0396562,20.375 5.51009084,19.905 C5.98052549,19.435 6.54604798,19.2 7.20665833,19.2 C7.86726867,19.2 8.43279117,19.435 8.90322581,19.905 C9.37366045,20.375 9.60887777,20.94 9.60887777,21.6 C9.60887777,22.26 9.37366045,22.825 8.90322581,23.295 C8.43279117,23.765 7.86726867,24 7.20665833,24 Z M19.2177555,24 C18.5571452,24 17.9916227,23.765 17.5211881,23.295 C17.0507534,22.825 16.8155361,22.26 16.8155361,21.6 C16.8155361,20.94 17.0507534,20.375 17.5211881,19.905 C17.9916227,19.435 18.5571452,19.2 19.2177555,19.2 C19.8783659,19.2 20.4438884,19.435 20.914323,19.905 C21.3847577,20.375 21.619975,20.94 21.619975,21.6 C21.619975,22.26 21.3847577,22.825 20.914323,23.295 C20.4438884,23.765 19.8783659,24 19.2177555,24 Z M6.18571506,4.8 L9.06837839,10.8 L17.4761464,10.8 L20.7791982,4.8 L6.18571506,4.8 Z M5.04466083,2.4 L22.7610292,2.4 C23.2214546,2.4 23.5717783,2.605 23.8120002,3.015 C24.0522222,3.425 24.0622314,3.84 23.842028,4.26 L19.5780885,11.94 C19.357885,12.34 19.0626122,12.65 18.69227,12.87 C18.3219279,13.09 17.9165533,13.2 17.4761464,13.2 L8.52787902,13.2 L7.20665833,15.6 L21.619975,15.6 L21.619975,18 L7.20665833,18 C6.30582603,18 5.62519719,17.605 5.1647718,16.815 C4.70434641,16.025 4.68432791,15.24 5.10471631,14.46 L6.72621444,11.52 L2.40221944,2.4 L0,2.4 L0,0 L3.90360659,0 L5.04466083,2.4 Z M9.06837839,10.845 L17.4761464,10.845 L9.06837839,10.845 Z"
        ></path>
      </g>
    </g>
  </g>
</svg>
