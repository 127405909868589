import {
  AdvisorActionTypes,
  FETCH_ADVISOR_SUCCESS,
  NEW_ADVISOR,
  NEW_ADVISOR_SUCCESS,
} from "~/actions/advisors"

import { InfoScreen, ScreenMediaType, ScreensState, ScreenType } from "./types"
import { ScreenActionTypes } from "~/actions/screens"
import {
  CHANGE_SCREEN_COLOR,
  CHANGE_SCREEN_CUSTOMER_INFO,
  CHANGE_SCREEN_INFO,
  CHANGE_SCREEN_LABEL,
  CHANGE_SCREEN_MEDIA_TYPE,
  CHANGE_SCREEN_TEXT,
  CHANGE_SCREEN_TITLE,
  REMOVE_INFO_SCREEN,
  UPDATE_ADVICE_SCREEN_DATA,
  UPDATE_INTRO_SCREEN_DATA,
} from "~/actions/screens/constants"
import { ADD_AFTER, FlowActionTypes } from "~/actions/flow"
import { QuestionColor } from "~/store/questions"
import { defaultInfoPageCustomerInfoConfig } from "~/lib/Advisor"

const defaultState = {}

export default (
  state: ScreensState = defaultState,
  action: AdvisorActionTypes | ScreenActionTypes | FlowActionTypes
): ScreensState => {
  switch (action.type) {
    case FETCH_ADVISOR_SUCCESS:
    case NEW_ADVISOR_SUCCESS: {
      const { screens, advisor } = action.payload
      return { ...state, [advisor.id]: screens }
    }
    case NEW_ADVISOR: {
      const advisor = action.payload.advisor
      return { ...state, [advisor.id]: advisor.screens }
    }

    case REMOVE_INFO_SCREEN: {
      const { id, advisorId } = action.payload
      const target = state[advisorId]
      return {
        ...state,
        [advisorId]: {
          ...target,
          infoScreens: target.infoScreens.filter((c) => c.id !== id),
        },
      }
    }

    case ADD_AFTER: {
      const { toAdd, advisorId } = action.payload
      const target = state[advisorId]

      if (!target) return state
      if (toAdd.type !== "infoScreen") return state

      return {
        ...state,
        [advisorId]: {
          ...target,
          infoScreens: [
            ...target.infoScreens,
            newInfoScreen(toAdd.id, toAdd.next),
          ],
        },
      }
    }

    // Update infoScreens
    case CHANGE_SCREEN_MEDIA_TYPE: {
      const { advisorId, id, mediaType } = action.payload

      return updateInfoScreen(state, advisorId, id, (c) => ({
        ...c,
        mediaType,
      }))
    }
    case CHANGE_SCREEN_TITLE: {
      const { advisorId, id, title } = action.payload
      return updateInfoScreen(state, advisorId, id, (c) => ({ ...c, title }))
    }
    case CHANGE_SCREEN_LABEL: {
      const { advisorId, id, label } = action.payload
      return updateInfoScreen(state, advisorId, id, (c) => ({ ...c, label }))
    }
    case CHANGE_SCREEN_COLOR: {
      const { advisorId, id, color } = action.payload
      return updateInfoScreen(state, advisorId, id, (c) => ({ ...c, color }))
    }
    case CHANGE_SCREEN_TEXT: {
      const { advisorId, id, text } = action.payload
      return updateInfoScreen(state, advisorId, id, (c) => ({ ...c, text }))
    }
    case CHANGE_SCREEN_INFO: {
      const { advisorId, id, info } = action.payload
      return updateInfoScreen(state, advisorId, id, (c) => ({ ...c, info }))
    }
    case CHANGE_SCREEN_CUSTOMER_INFO: {
      const { advisorId, id, customerInfo } = action.payload
      return updateInfoScreen(state, advisorId, id, (c) => ({
        ...c,
        customerInfo,
      }))
    }

    // case CHANGE_SCREEN_NEXT: {
    //   const { id, nextId, advisorId } = action.payload

    //   return {
    //     ...state,
    //     [advisorId]: {
    //       ...state[advisorId],
    //       infoScreens: state[advisorId].infoScreens.map(c => {
    //         if(c.id !== id) return c
    //         return {
    //           ...
    //         }

    //       })
    //       {
    //         ...state[advisorId].infoScreens,
    //         [id]: { ...state[advisorId].infoScreens[id], next: nextId },
    //       },
    //     },
    //   }
    // }

    case UPDATE_INTRO_SCREEN_DATA: {
      const { advisorId, title, text, info, buttonText, skipIntro } =
        action.payload
      const target = state[advisorId]
      return {
        ...state,
        [advisorId]: {
          ...target,
          intro: {
            ...target.intro,
            title,
            text,
            info,
            buttonText,
            skipIntro,
          },
        },
      }
    }
    case UPDATE_ADVICE_SCREEN_DATA: {
      const {
        advisorId,
        title,
        text,
        info,
        buttonText,
        bestMatchText,
        noResultsTitle,
        noResultsText,
        noResultsCustomerInfo,
        productScopeConfig,
        resultSize,
        showProductLink,
        sort,
        extraField1,
        extraField2,
        extraField3,
        showBestMatch,
        showMatchingIndicators,
        showPrice,
        showCurrency,
        showPricePrefix,
        showPriceSuffix,
        pricePrefix,
        priceSuffix,
        callToActionType,
        showReviewScore,
        showEnergyLabel,
      } = action.payload
      const target = state[advisorId]
      return {
        ...state,
        [advisorId]: {
          ...target,
          advice: {
            ...target.advice,
            title,
            text,
            info,
            buttonText,
            bestMatchText,
            noResultsTitle,
            noResultsText,
            noResultsCustomerInfo,
            resultSize,
            showProductLink,
            sort,
            extraField1,
            extraField2,
            extraField3,
            showBestMatch,
            showMatchingIndicators,
            showPrice,
            showCurrency,
            productScopeConfig,
            showPricePrefix,
            showPriceSuffix,
            pricePrefix,
            priceSuffix,
            callToActionType,
            showReviewScore,
            showEnergyLabel,
          },
        },
      }
    }

    default:
      return state
  }
}
function newInfoScreen(
  id: string,
  next: string | undefined
): import("./types").InfoScreen {
  return {
    id: id,
    type: ScreenType.INFO,
    label: "new info page",
    title: "",
    text: "",
    info: "",
    color: QuestionColor.Color1,
    mediaType: ScreenMediaType.SCREEN_WITH_NO_MEDIA,
    next: next,
    customerInfo: defaultInfoPageCustomerInfoConfig(),
  }
}

function updateInfoScreen(
  state: ScreensState,
  advisorId: string,
  id: string,
  fn: (c: InfoScreen) => InfoScreen
) {
  const target = state[advisorId]
  if (!target) return state
  return {
    ...state,
    [advisorId]: {
      ...target,
      infoScreens: target.infoScreens.map((c) => {
        if (c.id !== id) return c
        return fn(c)
      }),
    },
  }
}
