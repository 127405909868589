<script lang="ts">
  import ArrowLeft from "../icons/ArrowLeft.svelte"
  import ArrowRight from "../icons/ArrowRight.svelte"
  import { t } from "../lang/i18n"
  import Button from "./Button.svelte"
  import Bounded from "./Bounded.svelte"
  import { navigationContext } from "../stores/navigation"
  import Loader from "../icons/Loader.svelte"
  import { onMount } from "svelte"

  export let hideNext: boolean = false
  export let hidePrev: boolean = false
  export let nextDisabled: boolean = false
  export let disableSticky: boolean = false
  export let onNext: () => void = () => {}
  export let onPrev: () => void = () => {}

  let shouldStick = disableSticky
  $: {
    if (disableSticky) {
      shouldStick = false
    } else {
      shouldStick = $navigationContext.loadingNextScreen || !nextDisabled
    }
  }
  let stickyElem: HTMLElement | undefined = undefined
  let isSticking = false

  onMount(() => {
    if (stickyElem && "IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        ([e]) => {
          isSticking = e.intersectionRatio < 1
        },
        { threshold: [1] }
      )

      observer.observe(stickyElem)
    }
  })

  // The code below is used to only display the (hidden) loading button on single answer
  // questions after a small delay. This is to prevent flashing of a
  // loading state when you have a fast network. On other questions the Button is visible
  // and should show a loader immediately for good feedback.
  let showButtonLoader = false
  let timeout: any = undefined

  $: displayButtonLoading =
    showButtonLoader && $navigationContext.loadingNextScreen
  $: {
    if ($navigationContext.loadingNextScreen) {
      showButtonLoader = false
      timeout = setTimeout(
        () => {
          showButtonLoader = true
        },
        hideNext ? 300 : 0
      )
    } else {
      clearTimeout(timeout)
      showButtonLoader = false
    }
  }
</script>

<div
  class="bottom-nav max-app-width"
  class:should-stick={shouldStick}
  class:sticky={!disableSticky}
  class:is-sticking={isSticking}
  bind:this={stickyElem}
>
  <div></div>
  {#if !hideNext || displayButtonLoading}
    <span class="next-btn">
      <Button
        variant="primary"
        onClick={onNext}
        disabled={nextDisabled || displayButtonLoading}
      >
        <span id="next-button-label"> {$t("next")}</span>
        {#if displayButtonLoading}
          <Loader aria-label={$t("loading")} />
        {:else}
          <ArrowRight aria-labelledby="next-button-label"/>
        {/if}
      </Button>
    </span>
  {/if}
</div>

<style>
  .bottom-nav {
    margin-top: 1rem;
    min-height: 4rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
  .bottom-nav.is-sticking {
    & .next-btn > * {
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
    }
  }

  .next-btn {
    padding-top: 0.5rem;
    padding-bottom: calc(0.5rem + 1px);
  }
  .sticky {
    bottom: -1px;
    z-index: 100;
  }
  .sticky.should-stick {
    position: sticky;
  }
</style>
