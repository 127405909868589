import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Pokaż więcej",
  showLess: "Pokaż mniej",
  bestMatch: "Najlepszy mecz",
  view: "Zobacz",
  customAction: "",
  notFound: "404: nie znaleziono",
  reset: "Zacznij od nowa",
  close: "Zamknij",
  noAdviceTitle:
    "Obecnie nie ma dostępnych produktów, które w pełni odpowiadają Twoim preferencjom.",
  noAdviceMessage: "Dostosuj swoje preferencje lub spróbuj ponownie później.",
  prev: "Poprzednia",
  next: "Następna",
  backToAdvice: "Wracając do porad",
  dontKnow: "Pominąć",
  email: "Adres e-mail",
  send: "Wysłać",
  emailError: "Wprowadź prawidłowy adres e-mail",
  readMore: "Czytaj więcej",
  selectAnAnswer: "Wybierz odpowiedź",
  answerNotListed: "Nie ma tam mojej odpowiedzi",
  whyThisProductForMe: "Dlaczego mi to odpowiada?",
  alternative: "alternatywny",
  alternatives: "alternatywy",
  seeAlso: "Także popatrz",
  badMatchIcon: "Złe dopasowanie",
  goodMatchIcon: "Dobre dopasowanie",
  perfectMatchIcon: "Idealne dopasowanie",
  singleAnswerQuestionLegend: "Wybierz jedną opcję i kontynuuj.",
  multiAnswerQuestionLegend: "Wybierz jedną lub więcej opcji.",
  multiAnswerQuestionWithNeutralLegend: "Wybierz jedną lub więcej opcji lub opcję dolną, jeśli nie masz preferencji.",
  manualDrilldownLegend: "Wybierz jedną opcję. Jeśli pojawi się pytanie uzupełniające, pojawi się ono po wybraniu odpowiedzi. Po udzieleniu odpowiedzi na wszystkie pytania użyj przycisku „Następna”.",
  autoDrilldownLegend: "Wybierz jedną opcję. Jeśli pojawi się pytanie uzupełniające, pojawi się ono po wybraniu odpowiedzi. Po udzieleniu odpowiedzi na wszystkie pytania użyj przycisku „Następna”. Czy Twojej odpowiedzi nie ma na liście? Następnie wybierz „Nie ma tam mojej odpowiedzi”.",
  loading: "Ładowanie",
}

export default translations
