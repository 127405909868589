<script lang="ts">
  import { abTestStore } from "../ABTest"
  import { v4 as uuidv4 } from "uuid"
  import { type ProductCardPayload } from "../types"
  import {
    type ActionTriggered,
    type InternalAnalyticsProduct,
    type RecommendationContext,
    type SendInternalAnalytic,
  } from "../analytics/internal/types"
  import type { IntegrationsAnalyticsForComponents } from "../analytics/integrations/IntegrationsAnalytics"
  import { actionStore } from "../actions/ActionStore"
  import { type ActionState } from "../actions/types"
  import { advisorInfoStore } from "../stores"
  import Loader from "../icons/Loader.svelte"
  import Success from "../icons/Success.svelte"
  import Cart from "../icons/Cart.svelte"
  import Cross from "../icons/Cross.svelte"
  import Button from "../Button.svelte"
  import InternalAidenAnalytics from "../analytics/internal/InternalAnalytics"

  export let product: ProductCardPayload
  export let actionIdentifier: string

  export let customerAnalytics: IntegrationsAnalyticsForComponents
  export let analytic: SendInternalAnalytic

  export let recommendationContext: RecommendationContext
  export let location: ActionTriggered["location"]

  // export let context:
  //   | "PRODUCT_CHECK_PRODUCT"
  //   | "PRODUCT_CHECK_ALTERNATIVE"
  //   | "RECOMMENDATION"

  // export let position: number | null

  const triggerId = uuidv4()

  // let customerAnalyticsHandler:
  //   | ReturnType<typeof customActionAnalyticsHandler>
  //   | undefined = undefined
  // $: {
  //   customerAnalyticsHandler = customActionAnalyticsHandler(
  //     customerAnalytics,
  //     analytic,
  //     $abTestStore.activeVariant,
  //     context,
  //     position,
  //     customActionIdentifier,
  //     [content]
  //   )
  // }
  const internalProduct: InternalAnalyticsProduct = {
    id: product.productId,
    productId: product.clientProductId,
    name: product.productName,
    url: product.productUrl,
    price: product.specification.price,
    quantity: 1,
  }

  const onClick = (e: KeyboardEvent | MouseEvent) => {
    e.preventDefault()

    analytic(
      InternalAidenAnalytics.customActionEvent(
        actionIdentifier,
        {
          ...recommendationContext,
          product: internalProduct,
        },
        location,
        "PRODUCT_CTA",
        [internalProduct],
        $abTestStore.activeVariant
      )
    )

    actionStore.trigger(triggerId, {
      advisorId: $advisorInfoStore.advisorId,
      advisorName: $advisorInfoStore.advisorName,
      actionId: actionIdentifier,
      products: [
        {
          id: product.clientProductId,
          name: product.productName,
          quantity: 1,
        },
      ],
    })
    // customerAnalyticsHandler?.onClick()
  }

  let status: ActionState = "IDLE"
  $: {
    status = $actionStore[triggerId] || "IDLE"
  }
</script>

<Button isFullWidth isPrimary className="action-button" {onClick}>
  {#if status === "PENDING" || status === "ACKNOWLEDGED"}
    <Loader />
  {:else if status === "SUCCESS"}
    <Success />
  {:else if status === "FAILED"}
    <Cross />
  {:else}
    <Cart />
  {/if}
  <slot />
</Button>

<style>
  :global(.action-button) {
    font-size: 0.875rem !important;
    margin: 1rem 0 !important;
    line-height: 1.25 !important;
    text-wrap: balance !important;
    cursor: pointer !important;
    font-family: var(--aiden-font-family) !important;
    overflow-wrap: break-word !important;
    hyphens: manual !important;
    min-height: 45.5px;
    display: inline-flex;
    place-content: center;
    place-items: center;
  }
  :global(.action-button):visited,
  :global(.action-button) {
    box-shadow: var(--aiden-box-shadow-level-2);
  }
  :global(.action-button svg) {
    height: 1rem !important;
    width: 1rem !important;
    transform: scale(1.25);
    flex-shrink: 0;
  }
</style>
