import {
  parseCustomerInfoAdviceConfig,
  parseCustomerInfoFormConfig,
} from "./customerInfo/helpers"
import { sortTraceabilityByScore } from "../helpers/traceability_sorting"
import type {
  ActionConfig,
  AdviceCustomerInfoConfig,
  Control,
  CustomerInfoFormConfig,
  DrilldownOption,
  EnergyClassification,
  EnergyLabelField,
  ProductCard,
  ProductCardPayload,
  ProductField,
  ResultsControl,
  Screen,
  Traceability,
  UnionOfType,
  VariantProduct,
} from "../types"
import type { RecommendationContext } from "../analytics/internal/types"
import type { ProductData } from "../analytics/integrations/types"

export type InfoPage = {
  type: "info-page"
  screenId: string
  title: string
  text: string
  additionalText: string
  media: Media | undefined
  customerInfoForm: CustomerInfoFormConfig & { formIdentifier: string }
}

export type Page =
  | {
      type: "start-page"
      screenId: string
      title: string
      text: string
      additionalText: string
      buttonText: string
      /**
       * showOnlyButton is legacy support for old 'banner' apps placed on a lister.
       * Here you could hide the content of the start page to only display the button and minimize the apps real estate on your lister until its used.
       * */
      legacyShowOnlyButton: boolean
    }
  | InfoPage
  | {
      type: "question"
      screenId: string
      title: string
      text: string
      media: Media | undefined
      questionConfig: QuestionConfig
    }
  | {
      type: "advice-page"
      screenId: string
      title: string
      text: string
      additionalText: string
      recommendations: Recommendation[]
      customerInfoForm: AdviceCustomerInfoConfig
      actions: ActionConfig[]
    }
  | {
      type: "product-check-advice-page"
      screenId: string
      text: string
      isGoodMatch: boolean
      firstTag: {
        text: string
        variant: "good" | "neutral"
      }
      secondTag: {
        text: string
        variant: "good" | "neutral"
      }
      recommendations: {
        recommendation: Recommendation
        alternatives: Recommendation[]
      }
    }
  | {
      type: "empty-advice-page"
      screenId: string
      title: string
      text: string
      // additionalText: string
      customerInfoForm: CustomerInfoFormConfig
    }
  | {
      screenId: string
      type: "not-found"
      componentId: string
    }
  | {
      type: "error-page"
      screenId: string
    }

export function errorPage(): Page {
  return { type: "error-page", screenId: "error-page" }
}
export function toPage(
  screen: Screen,
  config: { introContent: "full" | "minimal" }
): Page {
  return oneOf(
    [
      parseIntroScreen(screen, config.introContent),
      parseInfoScreen(screen),
      parseQuestionScreen(screen),
      parseProductCheckAdviceScreen(screen),
      parseAdviceScreen(screen),
      parseEmptyAdviceScreen(screen),
    ],
    {
      type: "not-found",
      screenId: "not-found",
      componentId: screen.component || "unknown",
    }
  )
}

export type Media = { type: "image" | "video"; src: string }

function parseIntroScreen(
  screen: Screen,
  introContent: "full" | "minimal"
): Page | undefined {
  if (screen.component !== "intro") return

  const headerInfo = parseHeaderInformation(screen)
  const buttonText = getLabel(screen.controls, "button")

  if (headerInfo && buttonText !== undefined) {
    return {
      type: "start-page",
      screenId: screen.id,
      title: headerInfo.title,
      text: headerInfo.text,
      legacyShowOnlyButton: introContent === "minimal",
      additionalText: headerInfo.additionalText,
      buttonText,
    }
  }
  return
}
function parseInfoScreen(screen: Screen): Page | undefined {
  if (screen.component !== "info") return

  const headerInfo = parseHeaderInformation(screen)
  if (headerInfo) {
    return {
      type: "info-page",
      screenId: screen.id,
      title: headerInfo.title,
      text: headerInfo.text,
      additionalText: headerInfo.additionalText,
      media: headerInfo.media,
      customerInfoForm: {
        ...parseCustomerInfoFormConfig(screen.properties),
        formIdentifier: screen.properties.customerInfoFormIdentifier || "",
      },
    }
  }
  return
}

function parseAdviceScreen(screen: Screen): Page | undefined {
  if (screen.component !== "advice") return
  const resultsControl = screen.controls.find((c) => c.component === "results")
  if (!resultsControl) return
  if (resultsControl.component === "results") {
    if (resultsControl.controls.length === 0) return

    const title = getLabel(screen.controls, "h1") || ""
    const text = getLabel(screen.controls, "h2") || ""
    const additionalText = getLabel(screen.controls, "info-p") || ""
    const results = parseRegularRecommendations(resultsControl)

    const addAllToCartAction = parseAddAllToCartAction(screen)

    if (results.length) {
      return {
        type: "advice-page",
        screenId: screen.id,
        title: title,
        text: text,
        additionalText: additionalText,
        recommendations: results,
        actions: addAllToCartAction ? [addAllToCartAction] : [],
        customerInfoForm: parseCustomerInfoAdviceConfig(screen.properties),
      }
    }
  }
  return
}

function parseAddAllToCartAction(screen: Screen): ActionConfig | undefined {
  const actions = screen.actions || []
  const action = actions.find(
    (a) => a.type === "AddToCart" && a.id === "add-all-to-cart"
  )
  if (!action) return

  return {
    ...action,
    buttonType:
      action.buttonType === "PRIMARY"
        ? "primary"
        : action.buttonType === "SECONDARY"
        ? "secondary"
        : "primary",
  }
}

function parseEmptyAdviceScreen(screen: Screen): Page | undefined {
  if (screen.component !== "advice") return

  const title = screen.properties.noResultsTitle || ""
  const text = screen.properties.noResultsText || ""

  return {
    type: "empty-advice-page",
    screenId: screen.id,
    title: title,
    text: text,

    customerInfoForm: parseCustomerInfoFormConfig(screen.properties),
  }
}

function parseQuestionScreen(screen: Screen): Page | undefined {
  if (screen.component !== "question") return
  const headerInfo = parseHeaderInformation(screen)
  const questionConfig = parseQuestionConfig(screen.controls)
  const media = getMedia(screen.controls)
  if (!headerInfo) return
  if (!questionConfig) return
  return {
    type: "question",
    screenId: screen.id,
    title: headerInfo.title,
    text: headerInfo.text,
    media: media,
    questionConfig: questionConfig,
  }
}

export type QuestionConfig =
  | {
      type: "single"
      complete: boolean

      attributeInstanceId: string
      neutralAnswerValue: string | undefined
      options: PredefinedOption[]
    }
  | {
      type: "multi"
      complete: boolean
      attributeInstanceId: string
      neutralAnswerValue: string | undefined
      options: PredefinedOption[]
    }
  | {
      type: "numeric"
      complete: boolean
      attributeInstanceId: string
      skipAnswerOption: string | undefined
      config: {
        allowsDecimals: boolean
        inputLabel: string
        inputInfoLabel: string
        inputInfoPosition: "prefix" | "suffix"
      }
    }
  | {
      type: "manual-drilldown"
      complete: boolean
      attributeInstanceId: string
      levelLabels: string[]
      options: DrilldownOption[]
    }
  | {
      type: "auto-drilldown"
      complete: boolean
      attributeInstanceId: string
      levelsConfiguration: LevelsConfiguration[]
      neutralAnswerValue: string | undefined
    }

type LevelsConfiguration = {
  label: string
  sourceField: string
}
export type PredefinedOption = {
  label: string
  helpText: string | undefined
  image: string | undefined
  value: string
}

function parseQuestionConfig(controls: Control[]): QuestionConfig | undefined {
  return (
    [
      parseSingleQuestion(controls),
      parseMultiQuestion(controls),
      parseNumericQuestion(controls),
      parseManualDrilldown(controls),
      parseAutoDrilldown(controls),
    ].find((config) => config !== undefined) || undefined
  )
}

function parseSingleQuestion(controls: Control[]): QuestionConfig | undefined {
  return parsePredefined("single", controls)
}
function parseMultiQuestion(controls: Control[]): QuestionConfig | undefined {
  return parsePredefined("multi", controls)
}
function parseNumericQuestion(controls: Control[]): QuestionConfig | undefined {
  const control = controls.find((c) => c.component === "number")
  if (control && control.component === "number") {
    return {
      type: "numeric",
      complete: control.complete || false,
      attributeInstanceId: control.attributeInstanceId,
      skipAnswerOption: control.properties.skipAnswer,
      config: {
        allowsDecimals: control.properties["allowsDecimals"] === "true",
        inputLabel: control.properties["placeholder"] || "",
        inputInfoLabel: control.properties["inputInfoLabel"] || "",
        inputInfoPosition:
          control.properties["inputInfoPosition"]?.toLowerCase() === "prefix"
            ? "prefix"
            : "suffix",
      },
    }
  }
  return
}

function parseManualDrilldown(controls: Control[]): QuestionConfig | undefined {
  const control = controls.find((c) => c.component === "drill-down")
  if (control && control.component === "drill-down") {
    return {
      type: "manual-drilldown",
      complete: control.complete || false,
      attributeInstanceId: control.attributeInstanceId,
      levelLabels: control.payload["levelsConfiguration"] || [],
      options: control.payload["options"] || [],
    }
  }
  return
}

function parseAutoDrilldown(controls: Control[]): QuestionConfig | undefined {
  const control = controls.find((c) => c.component === "auto-drill-down")
  if (control && control.component === "auto-drill-down") {
    return {
      type: "auto-drilldown",
      complete: control.complete || false,
      attributeInstanceId: control.attributeInstanceId,
      levelsConfiguration: control.payload["levelsConfiguration"] || [],
      neutralAnswerValue: control.properties["neutralAnswer"] || undefined,
    }
  }
  return
}

function parsePredefined(
  kind: "single" | "multi",
  controls: Control[]
): QuestionConfig | undefined {
  const componentId = kind === "single" ? "radio" : "checkbox"
  const control = controls.find((c) => c.component === componentId)
  if (!control) return
  if (control.component === componentId) {
    const helpText = (id: string) =>
      control.properties[`${id}.help`] || undefined
    const image = (id: string) => control.properties[`${id}.image`] || undefined

    const options = control.options.map((opt) => ({
      value: opt.value,
      label: opt.label,
      helpText: helpText(opt.value),
      image: image(opt.value),
    }))

    return {
      type: kind,
      complete: control.complete || false,
      neutralAnswerValue: control.properties.neutralAnswer,
      options: options,
      attributeInstanceId: control.attributeInstanceId,
    }
  }
  return
}

function parseHeaderInformation(screen: Screen):
  | {
      title: string
      text: string
      additionalText: string
      media: Media | undefined
    }
  | undefined {
  const title = getLabel(screen.controls, "h1")
  const text = getLabel(screen.controls, "p")
  const additionalText = getLabel(screen.controls, "info-p") || ""
  const media = getMedia(screen.controls)
  if (
    title !== undefined &&
    text !== undefined &&
    additionalText !== undefined
  ) {
    return {
      title,
      text,
      additionalText,
      media,
    }
  }
  return undefined
}

function getLabel(controls: Control[], name: string): string | undefined {
  return controls.find((ctrl) => ctrl.component === name)?.label
}
function getMedia(controls: Control[]): Media | undefined {
  const image = controls.find((c) => c.component === "image")?.properties?.src
  const video = controls.find((c) => c.component === "video")?.payload?.embed
  if (image) {
    return { type: "image", src: image }
  }
  if (video) {
    return { type: "video", src: video }
  }
  return
}

function oneOf<T>(list: (T | undefined)[], defaultValue: T): T {
  return list.find((page) => page !== undefined) || defaultValue
}

function parseRegularRecommendations(
  resultsControl: ResultsControl
): RegularRecommendation[] {
  const results = resultsControl.controls
    .filter((ctrl) => ctrl.component === "product-card")
    .map((ctrl, order) =>
      parseRegularRecommendation(ctrl as ProductCard, order)
    )
  if (results.some((r) => r === undefined)) return []

  return results as RegularRecommendation[]
}

function parseProductCheckAdviceScreen(screen: Screen): Page | undefined {
  const controls = screen.controls
  const productCheckCtrl = controls.find(
    (ctrl) => ctrl.component === "pdp-card"
  )

  const resultsControl = controls.find((ctrl) => ctrl.component === "results")
  if (
    resultsControl?.component === "results" &&
    productCheckCtrl?.component === "pdp-card"
  ) {
    const results = resultsControl.controls
      .filter((ctrl) => ctrl.component === "product-card")
      .map((ctrl, order) =>
        parseRecommendation(ctrl.payload as ProductCardPayload, order)
      )

    if (results.some((r) => r === undefined)) return

    const productCheckMatch = productCheckCtrl.payload["pdp-match"]
    const isGoodMatch =
      productCheckMatch === "GOOD" || productCheckMatch === "BEST"
    if (!productCheckMatch) return
    const productCheckGoodMatchIntro =
      productCheckCtrl.payload.productCheckGoodMatchIntro
    const productCheckGoodMatchLabel =
      productCheckCtrl.payload.productCheckGoodMatchLabel
    const productCheckGoodMatchAlternativeLabel =
      productCheckCtrl.payload.productCheckGoodMatchAlternativeLabel
    const productCheckBadMatchIntro =
      productCheckCtrl.payload.productCheckBadMatchIntro
    const productCheckBadMatchLabel =
      productCheckCtrl.payload.productCheckBadMatchLabel
    const productCheckBadMatchAlternativeLabel =
      productCheckCtrl.payload.productCheckBadMatchAlternativeLabel
    const productCheckBadMatchNoAlternativesIntro =
      productCheckCtrl.payload.productCheckBadMatchNoAlternativesIntro

    const productCheckProductRecommendation = parseRecommendation(
      productCheckCtrl.payload as ProductCardPayload,
      0
    )
    if (!productCheckProductRecommendation) return
    const productCheckProduct: Recommendation = {
      ...productCheckProductRecommendation,
      isProductCheckProduct: true,
      recommendationContext: {
        recommendationPosition: undefined,
        productContext: "PRODUCT_CHECK_PRODUCT",
      },
      variants: productCheckProductRecommendation.variants.map((v) => ({
        ...v,
        productContext: "PRODUCT_CHECK_PRODUCT_VARIANT",
      })),
      traceability: isGoodMatch
        ? productCheckProductRecommendation.traceability
        : sortTraceabilityByScore(
            productCheckProductRecommendation.traceability,
            ["bad"]
          ),
    }

    const alternatives: Recommendation[] = (results as Recommendation[]).map(
      (alt, i) => ({
        ...alt,
        recommendationContext: {
          recommendationPosition: i + 1,
          productContext: "PRODUCT_CHECK_ALTERNATIVE",
        },
        variants: alt.variants.map((v) => ({
          ...v,
          productContext: "PRODUCT_CHECK_ALTERNATIVE_VARIANT",
        })),
      })
    )

    return {
      type: "product-check-advice-page",
      screenId: screen.id,
      text: isGoodMatch
        ? productCheckGoodMatchIntro
        : alternatives.length
        ? productCheckBadMatchIntro
        : productCheckBadMatchNoAlternativesIntro,
      isGoodMatch: isGoodMatch,
      firstTag: {
        text: isGoodMatch
          ? productCheckGoodMatchLabel
          : productCheckBadMatchLabel,
        variant: isGoodMatch ? "good" : "neutral",
      },
      secondTag: {
        text: isGoodMatch
          ? productCheckGoodMatchAlternativeLabel
          : productCheckBadMatchAlternativeLabel,
        variant: isGoodMatch ? "neutral" : "good",
      },
      recommendations: {
        recommendation: productCheckProduct,
        alternatives: alternatives,
      },
    }
  }

  return
}

function parseRegularRecommendation(control: ProductCard, order: number) {
  const r = parseRecommendation(control.payload, order)
  if (!r) return
  if (order === 0) {
    return {
      ...r,
      recommendationContext: {
        recommendationPosition: order + 1,
        productContext: "RECOMMENDATION",
      },
      state: control.payload.showBestMatch
        ? {
            type: "best-match",
            bestMatchText: control.payload.bestMatchText,
          }
        : undefined,
    }
  } else {
    return r
  }
}

function parseRecommendation(
  payload: ProductCardPayload,
  order: number
): Recommendation | undefined {
  const price = payload.price
  const salePrice = payload.salePrice
  const salePeriodStart = payload.salePeriodStart
  const salePeriodEnd = payload.salePeriodEnd
  const productLinkActive = payload.showProductLink

  const now = Date.now()
  const saleIsNotTheSameAsPrice = salePrice && salePrice !== price

  let salePriceActive = false
  if (salePeriodStart && salePeriodEnd && saleIsNotTheSameAsPrice) {
    salePriceActive = now >= salePeriodStart && now <= salePeriodEnd
  } else if (saleIsNotTheSameAsPrice) {
    salePriceActive = true
  } else {
    salePriceActive = false
  }

  const energyLabelField: EnergyLabelField | undefined =
    parseEnergyLabel(payload)

  return {
    state: undefined,
    recommendationContext: {
      recommendationPosition: order + 1,
      productContext: "RECOMMENDATION",
    },
    productId: payload.productId,
    isProductCheckProduct: false,
    clientProductId: payload.clientProductId,
    productUrl: productLinkActive ? payload.productUrl || undefined : undefined,
    imageUrl: payload.imageUrl,
    productName: payload.productName,
    price: price || undefined,
    salePrice:
      salePrice && salePriceActive
        ? {
            price: salePrice,
            salePeriodStart: salePeriodStart || undefined,
            salePeriodEnd: salePeriodEnd || undefined,
            previousPriceAnnotation: payload.priceTypeAnnotation || "",
          }
        : undefined,
    productData: payload.data,
    specification: payload.specification,
    traceability: sortTraceabilityByScore(payload.features, [
      "good",
      "perfect",
      "bad",
    ]),
    buttonCtaText: payload.cta,
    energyLabelField: energyLabelField,
    additionalFields: payload.fields,
    pricePrefix: payload.pricePrefix,
    priceSuffix: payload.priceSuffix,
    reviewScore: payload.reviewScore || undefined,
    reviewCount: payload.reviewCount || undefined,
    variants: payload.variants.map((v) => ({
      ...v,
      productContext: "RECOMMENDATION_VARIANT",
    })),
    display: {
      bestMatch: payload.showBestMatch,
      traceability: payload.showMatchingIndicators,
      price: payload.showPrice,
      productCta: productLinkActive,
      ctaType: payload.ctaType === "AddToCart" ? "AddToCart" : "ProductLink",
    },
  }
}

export type RegularRecommendation = UnionOfType<
  Recommendation & {
    bestMatchTag: undefined | { bestMatchText: string }
  }
>

export type Recommendation = {
  state: undefined | { type: "best-match"; bestMatchText: string }
  recommendationContext: RecommendationContext
  productId: string
  clientProductId: string
  productUrl: string | undefined
  imageUrl: string
  productName: string
  price: string | undefined
  isProductCheckProduct: boolean
  salePrice:
    | {
        price: string
        previousPriceAnnotation: string
        salePeriodStart: number | undefined
        salePeriodEnd: number | undefined
      }
    | undefined
  productData: ProductData
  specification: {
    price: {
      value: number
      currency: string
    } | null
  }
  traceability: Traceability[]
  buttonCtaText: string
  energyLabelField: EnergyLabelField | undefined
  additionalFields: ProductField[]
  pricePrefix: string
  priceSuffix: string
  variants: VariantProduct[]
  reviewScore: number | undefined
  reviewCount: number | undefined
  display: {
    bestMatch: boolean
    traceability: boolean
    price: boolean
    productCta: boolean
    ctaType: "ProductLink" | "AddToCart"
  }
}

function parseEnergyLabel(
  payload: ProductCardPayload
): EnergyLabelField | undefined {
  const energyClassification = parseEnergyClassification(payload)

  const productSheet =
    payload.energyLabelSheetLinkText && payload.energyLabelSheetLink
      ? {
          label: payload.energyLabelSheetLinkText,
          url: payload.energyLabelSheetLink,
        }
      : undefined

  if (productSheet || energyClassification) {
    return {
      type: "energy-label",
      energyLabel: energyClassification,
      productSheet: productSheet,
    }
  }
  return
}

function parseEnergyClassification(
  payload: ProductCardPayload
): EnergyClassification | undefined {
  if (
    payload.energyLabelScale &&
    payload.energyLabelClass &&
    payload.energyLabelLink &&
    ["G", "F", "E", "D", "C", "B", "A", "A+", "A++", "A+++"].includes(
      payload.energyLabelClass
    ) &&
    ["EU-G-A", "EU-G-A+++", "EU-D-A+++", "EU-E-A++"].includes(
      payload.energyLabelScale
    )
  ) {
    const energyLabel: EnergyClassification = {
      scale: payload.energyLabelScale as EnergyClassification["scale"],
      classification:
        payload.energyLabelClass as EnergyClassification["classification"],
      labelLink: payload.energyLabelLink,
    }
    return energyLabel
  }
  return
}
