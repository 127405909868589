<script lang="ts">
  import { onMount } from "svelte"
  import type { OptionsFor } from "../../types"
  import DrilldownSelect from "../../ui/DrilldownSelect.svelte"
  import type { AutoDrilldownAnswer } from "../answering"
  import Loader from "../../icons/Loader.svelte";
  import { t } from "../../lang/i18n"
  import FieldsetWithLegend from "../../ui/FieldsetWithLegend.svelte"

  export let onChange: (value: string[]) => void
  export let autoDrilldownAnswerValue: AutoDrilldownAnswer

  type LevelsConfiguration = {
    label: string
    sourceField: string
  }

  let isLoading = false
  let fields: { title: string }[][] = []

  export let levelsConfiguration: LevelsConfiguration[]
  // levelsConfiguration
  const sourceFields = levelsConfiguration.map((conf) => conf.sourceField)
  const levels: number = levelsConfiguration.length
  let selections: string[] = [...Array(levels).keys()].map(() => "")

  export let optionsFor: (
    field: string,
    selectedAnswers: {
      field: string
      value: string
    }[]
  ) => ReturnType<OptionsFor>

  const getSelected = (index: number) => {
    return [...Array(index).keys()].map((i) => {
      return {
        field: sourceFields[i],
        value: selections[i],
      }
    })
  }

  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  })

  let sort = (options: { title: string }[]): { title: string }[] =>
    options.sort((a, b) => collator.compare(a.title, b.title))

  onMount(() => {
    if (autoDrilldownAnswerValue.type === "neutral-answer") {
      onChange([])
    }
  })

  const getOptions = (
    index: number,
    field: string,
    selected: { field: string; value: string }[]
  ) => {
    isLoading = true
    optionsFor &&
      optionsFor(field, selected)
        .then((resp) => {
          const opts = resp.map(({ value }) => ({
            title: value,
          }))
          fields[index] = sort(opts)

          if (!opts.find((o) => o.title === selections[index])) {
            resetSelection(index)
            if (autoDrilldownAnswerValue.type !== "neutral-answer") {
              onChange([])
            }
          }
        })
        .finally(() => {
          isLoading = false
        })
  }

  if (autoDrilldownAnswerValue.type === "answer") {
    const values: Record<string, string> = autoDrilldownAnswerValue.value
      .map((val) => {
        const index = val[0]
        const value = val.slice(val.indexOf(":") + ":".length)
        return [index, value]
      })
      .reduce((acc, [index, value]) => {
        return { ...acc, [index]: value }
      }, {})
    selections = selections.map((s, i) => values[i.toString()])
  }

  ;[...Array(levels).keys()].forEach((level) => {
    if (
      (levelsConfiguration[0]?.sourceField.trim() && level === 0) ||
      (selections[level] !== "" && levels > level)
    ) {
      const selected = getSelected(level)
      const field = levelsConfiguration[level]?.sourceField
      if (field) {
        getOptions(level, field, selected)
      }
    }
  })

  let select = (value: string, index: number) => {
    if (selections[index] !== value) {
      selections[index] = value
      resetSelection(index + 1)
    }
    const selected = getSelected(index).concat([
      { field: sourceFields[index], value: value },
    ])
    const selection = selected.map((s, i) => `${i}:${s.value}`)
    if (index < levels - 1) {
      if (levels > index + 1) {
        getOptions(
          index + 1,
          sourceFields[index + 1],
          getSelected(index).concat([
            { field: sourceFields[index], value: value },
          ])
        )
      } else {
        onChange(selection)
      }
    } else {
      onChange(selection)
    }
  }

  const resetSelection = (index: number) => {
    for (let k = levels - 1; k >= index; k--) selections[k] = ""
  }
</script>

<div class:isLoading>
  {#if isLoading && fields.length !== selections.length}
    <Loader aria-label={$t("loading")}/>
  {:else}
    <FieldsetWithLegend legend={$t("autoDrilldownLegend")}>
      {#each selections as selection, i}
        {#if (i === 0 || selections[i - 1] !== "") && levels > i}
          <div class="mb-md">
            <DrilldownSelect
              id="step-{i + 1}"
              label={levelsConfiguration[i]?.label || ""}
              value={selection || ""}
              options={fields[i] && fields[i].length > 0
                ? fields[i]
                : selection
                  ? [{ title: selection }]
                  : []}
              onSelect={(value) => select(value, i)}
            />
          </div>
        {/if}
      {/each}
    </FieldsetWithLegend>
  {/if}
</div>

<style>
  div {
    transition: opacity 250ms ease-out;
    /* margin: 2rem 0; */
  }
  .isLoading {
    pointer-events: none;
    opacity: 0.5;
  }
</style>
