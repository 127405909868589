import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Näytä lisää",
  showLess: "Näytä vähemmän",
  bestMatch: "Paras ottelu",
  view: "Näytä",
  customAction: "",
  notFound: "404: ei löydetty",
  reset: "Aloittaa alusta",
  close: "Kiinni",
  noAdviceTitle:
    "Tällä hetkellä ei ole saatavilla tuotteita, jotka vastaavat täysin mieltymyksiäsi.",
  noAdviceMessage: "Voit muuttaa asetuksiasi tai yrittää myöhemmin uudelleen.",
  prev: "Edellinen",
  next: "Seuraava",
  backToAdvice: "Takaisin neuvoihin",
  dontKnow: "Ohita",
  email: "Sähköpostiosoite",
  send: "Lähetä",
  emailError: "Ole hyvä ja syötä toimiva sähköpostiosoite",
  readMore: "Lue lisää",
  selectAnAnswer: "Valitse vastaus",
  answerNotListed: "Vastaustani ei ole luettelossa",
  whyThisProductForMe: "Miksi tämä neuvo?",
  alternative: "vaihtoehto",
  alternatives: "vaihtoehtoja",
  seeAlso: "Katso myös",
  badMatchIcon: "Huono vastaavuus",
  goodMatchIcon: "Hyvä vastaavuus",
  perfectMatchIcon: "Täydellinen vastaavuus",
  singleAnswerQuestionLegend: "Valitse yksi vaihtoehto ja jatka.",
  multiAnswerQuestionLegend: "Valitse yksi tai useampi vaihtoehto.",
  multiAnswerQuestionWithNeutralLegend: "Valitse yksi tai useampi vaihtoehto tai alempi vaihtoehto, jos et halua.",
  manualDrilldownLegend: "Valitse yksi vaihtoehto. Jos on jatkokysymys, se tulee näkyviin vastauksen valinnan jälkeen. Käytä 'Seuraava'-painiketta jatkaaksesi, kun olet vastannut kaikkiin kysymyksiin.",
  autoDrilldownLegend: "Valitse yksi vaihtoehto. Jos on jatkokysymys, se tulee näkyviin vastauksen valinnan jälkeen. Käytä 'Seuraava'-painiketta jatkaaksesi, kun olet vastannut kaikkiin kysymyksiin. Eikö vastaustasi ole luettelossa? Valitse sitten 'Vastaustani ei ole luettelossa'.",
  loading: "Ladataan",
}

export default translations
