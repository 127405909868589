import React, { ReactNode, useCallback, useEffect, useState } from "react"
import FieldGroup from "~/components/forms/FieldGroup"
import FormSection from "~/components/forms/FormSection"
import Form from "~/components/forms/Form"
import Button from "~/components/Button"
import LoadingSpinner from "~/components/Loading"
import { useTranslation } from "react-i18next"
import HelpText from "~/components/HelpText"
import { useAuth } from "~/context/auth"
import organisations from "~/api/organisations"
import ToggleSwitch from "../ToggleSwitch"
import FormSplit from "../forms/FormSplit"
import styled from "styled-components"
import Card from "~/components/Card"
import { FeatureFlag, FeatureFlags, useFeatureFlags } from "~/hooks/useFeatureFlags"
import { SingleSelect } from "../selection"
import { useProductFieldAvailability } from "./useProductFieldAvailability"
import useNavigation from "~/hooks/useNavigation"
import { Link } from "react-router-dom"
import { FieldAvailability } from "~/api/integrations"
import Warning from "../icons/Warning"
import sortBy from "lodash/sortBy"
import useNotifications from "~/hooks/useNotifications"
import TutorialLink from "~/components/TutorialLink"

const IntegrationCard = styled(Card)`
  display: grid;
  width: 100%;
  gap: 1.5rem;

  img {
    max-height: 3rem;
    display: block;
    /* align-self: center; */
  }
  .integration--toggle {
    display: flex;
    justify-content: space-between;
    /* justify-self: end; */
  }
  .integration--info {
    display: grid;
    width: 100%;
    /* grid-template-columns: 12rem auto; */
    gap: 1rem;
    p {
      margin: 0;
      font-size: 0.875rem;
      color: ${(p) => p.theme.shade5};
    }
    .warning p {
      color: ${(p) => p.theme.main};
    }
  }
`

const Integrations = () => {
  const { t } = useTranslation()
  const { token, organisationId } = useAuth()
  const [spotlerActivateLegacyIntegration, setSpotlerActivateLegacyIntegration] = useState<{
    enabled: boolean
    productIdField: string | null
  }>({
    enabled: false,
    productIdField: null,
  })

  const [spotlerActivateIntegration, setSpotlerActivateIntegration] = useState<{
    enabled: boolean
    productIdField: string | null
  }>({
    enabled: false,
    productIdField: null,
  })

  const [googleTagManagerIntegration, setGoogleTagManagerIntegration] =
    useState<{
      enabled: boolean
    }>({
      enabled: false,
    })

  const [googleAnalyticsIntegration, setGoogleAnalyticsIntegration] = useState<{
    enabled: boolean
  }>({
    enabled: false,
  })

  const [bloomReachIntegration, setBloomReachIntegration] = useState<{
    enabled: boolean
  }>({
    enabled: false,
  })

  const [customIntegration, setCustomIntegration] = useState<{
    enabled: boolean
  }>({
    enabled: false,
  })
  const [loading, setLoading] = useState(true)
  const { notify } = useNotifications()
  const { isChecking, checkField, clearField, advisorsWithMissingField } =
    useProductFieldAvailability(token, organisationId)
  const {
    isChecking: isCheckingSpotlerActivate,
    checkField: checkFieldSpotlerActivate,
    clearField: clearFieldSpotlerActivate,
    advisorsWithMissingField: advisorsWithMissingFieldSpotlerActivate
  } = useProductFieldAvailability(token, organisationId)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(false)
  const { featureFlags } = useFeatureFlags()

  const anyIntegrationsEnabled =
    featureFlags.bloomreachEnabled ||
    featureFlags.customIntegrationEnabled ||
    featureFlags.bloomreachEnabled ||
    featureFlags.googleAnalyticsEnabled ||
    featureFlags.spotlerActivateLegacyEnabled ||
    featureFlags.spotlerActivateEnabled

  const checkMatchingAdvisorsForField = useCallback((value: string | null) => {
    if (value === null) {
      clearField()
    } else {
      clearField()
      checkField(value)
    }
  }, [])

  const checkMatchingAdvisorsForFieldSpotlerActivate = useCallback((value: string | null) => {
    if (value === null) {
      clearFieldSpotlerActivate()
    } else {
      clearFieldSpotlerActivate()
      checkFieldSpotlerActivate(value)
    }
  }, [])

  const save = useCallback(() => {
    if (anyIntegrationsEnabled) {
      let isMounted = true
      setError(false)
      setSaving(true)
      const justResolve = Promise.resolve(undefined)

      Promise.all([
        featureFlags.spotlerActivateLegacyEnabled
          ? organisations.updateSpotlerActivateLegacyIntegration(
              token!,
              organisationId,
              {
                enabled: spotlerActivateLegacyIntegration.enabled,
                productIdField: spotlerActivateLegacyIntegration.productIdField,
              }
            )
          : justResolve,
        featureFlags.spotlerActivateEnabled
          ? organisations.updateSpotlerActivateIntegration(
            token!,
            organisationId,
            {
              enabled: spotlerActivateIntegration.enabled,
              productIdField: spotlerActivateIntegration.productIdField,
            }
          )
          : justResolve,
        featureFlags.googleAnalyticsEnabled
          ? organisations.updateGoogleTagManagerIntegration(
              token!,
              organisationId,
              {
                enabled: googleTagManagerIntegration.enabled,
              }
            )
          : justResolve,
        featureFlags.googleAnalyticsEnabled
          ? organisations.updateGoogleAnalyticsIntegration(
              token!,
              organisationId,
              {
                enabled: googleAnalyticsIntegration.enabled,
              }
            )
          : justResolve,
        featureFlags.customIntegrationEnabled
          ? organisations.updateCustomIntegration(token!, organisationId, {
              enabled: customIntegration.enabled,
            })
          : justResolve,
        featureFlags.bloomreachEnabled
          ? organisations.updateBloomReachIntegration(token!, organisationId, {
              enabled: bloomReachIntegration.enabled,
            })
          : justResolve,
      ])
        .then(() => {
          notify({
            text: "Changes saved!",
            type: "success",
          })
        })
        .catch(() => {
          if (isMounted) setError(true)
          notify({
            text: "Something went wrong. Please try again or contact support if the problem persists",
            type: "error",
          })
        })
        .finally(() => {
          if (isMounted) setSaving(false)
        })

      return () => {
        isMounted = false
      }
    }
  }, [
    anyIntegrationsEnabled,
    featureFlags.bloomreachEnabled,
    featureFlags.spotlerActivateLegacyEnabled,
    featureFlags.spotlerActivateEnabled,
    featureFlags.googleAnalyticsEnabled,
    featureFlags.customIntegrationEnabled,
    spotlerActivateLegacyIntegration,
    spotlerActivateIntegration,
    googleTagManagerIntegration,
    googleAnalyticsIntegration,
    customIntegration,
    bloomReachIntegration,
    organisationId,
    token,
  ])

  const ifIntegrationsEnabled =
    <T,>(fn: (arg: T) => void, ff: FeatureFlag) =>
    (value: T) => {
      if (featureFlags[ff] || false) fn(value)
    }

  const setSpotlerActivateLegacy = ifIntegrationsEnabled(
    setSpotlerActivateLegacyIntegration,
    FeatureFlag.spotlerActivateLegacyEnabled
  )
  const setSpotlerActivate = ifIntegrationsEnabled(
    setSpotlerActivateIntegration,
    FeatureFlag.spotlerActivateEnabled
  )
  const setGoogleTagManager = ifIntegrationsEnabled(
    setGoogleTagManagerIntegration,
    FeatureFlag.googleAnalyticsEnabled
  )
  const setGoogleAnalytics = ifIntegrationsEnabled(
    setGoogleAnalyticsIntegration,
    FeatureFlag.googleAnalyticsEnabled
  )
  const setCustom = ifIntegrationsEnabled(
    setCustomIntegration,
    FeatureFlag.customIntegrationEnabled
  )
  const setBloomReach = ifIntegrationsEnabled(
    setBloomReachIntegration,
    FeatureFlag.bloomreachEnabled
  )

  useEffect(() => {
    let isMounted = true
    if (!anyIntegrationsEnabled) {
      setLoading(false)
      return () => {
        isMounted = false
      }
    }
    const justResolve = Promise.resolve(undefined)
    if (token) {
      Promise.all([
        featureFlags.spotlerActivateLegacyEnabled
          ? organisations.getSpotlerActivateLegacyIntegration(token, organisationId)
          : justResolve,
        featureFlags.spotlerActivateEnabled
          ? organisations.getSpotlerActivateIntegration(token, organisationId)
          : justResolve,
        featureFlags.googleAnalyticsEnabled
          ? organisations.getGoogleTagManagerIntegration(token, organisationId)
          : justResolve,
        featureFlags.googleAnalyticsEnabled
          ? organisations.getGoogleAnalyticsIntegration(token, organisationId)
          : justResolve,
        featureFlags.customIntegrationEnabled
          ? organisations.getCustomIntegration(token, organisationId)
          : justResolve,
        featureFlags.bloomreachEnabled
          ? organisations.getBloomReachIntegration(token, organisationId)
          : justResolve,
      ])
        .then(
          ([
            spotlerActivateLegacyIntegration,
            spotlerActivateIntegration,
            googleTagManagerIntegration,
            googleAnalyticsIntegration,
            customIntegration,
            bloomReachIntegration,
          ]) => {
            if (isMounted) {
              if (spotlerActivateLegacyIntegration) {
                setSpotlerActivateLegacyIntegration({
                  enabled: spotlerActivateLegacyIntegration.enabled,
                  productIdField: spotlerActivateLegacyIntegration.productIdField,
                })
                checkMatchingAdvisorsForField(
                  spotlerActivateLegacyIntegration.productIdField
                )
              }
              if (spotlerActivateIntegration) {
                setSpotlerActivateIntegration({
                  enabled: spotlerActivateIntegration.enabled,
                  productIdField: spotlerActivateIntegration.productIdField,
                })
                checkMatchingAdvisorsForFieldSpotlerActivate(
                  spotlerActivateIntegration.productIdField
                )
              }
              if (googleTagManagerIntegration) {
                setGoogleTagManagerIntegration({
                  enabled: googleTagManagerIntegration.enabled,
                })
              }
              if (googleAnalyticsIntegration) {
                setGoogleAnalyticsIntegration({
                  enabled: googleAnalyticsIntegration.enabled,
                })
              }
              if (customIntegration) {
                setCustomIntegration({
                  enabled: customIntegration.enabled,
                })
              }
              if (bloomReachIntegration) {
                setBloomReachIntegration({
                  enabled: bloomReachIntegration.enabled,
                })
              }
            }
          }
        )
        .catch((_) => {
          if (isMounted) setError(true)
        })
        .finally(() => {
          if (isMounted) setLoading(false)
        })
    }
    return () => {
      isMounted = false
    }
  }, [token, organisationId])

  const formList = [
    {
      enabled: featureFlags.googleAnalyticsEnabled,
      category: "analytics",
      form: googleAnalyticsForm(
        googleTagManagerIntegration,
        setGoogleTagManager,
        featureFlags,
        googleAnalyticsIntegration,
        setGoogleAnalytics
      ),
    },
    {
      enabled: featureFlags.spotlerActivateEnabled,
      category: "cdp",
      form: spotlerActivateForm(
        spotlerActivateIntegration,
        setSpotlerActivate,
        featureFlags,
        checkMatchingAdvisorsForFieldSpotlerActivate,
        isCheckingSpotlerActivate,
        advisorsWithMissingFieldSpotlerActivate
      ),
    },
    {
      category: "cdp",
      enabled: featureFlags.bloomreachEnabled,
      form: bloomreachForm(featureFlags, bloomReachIntegration, setBloomReach),
    },
    {
      category: "custom",
      enabled: featureFlags.customIntegrationEnabled,
      form: customIntegrationForm(customIntegration, setCustom, featureFlags),
    },
    {
      enabled: featureFlags.spotlerActivateLegacyEnabled,
      category: "cdp",
      form: spotlerActivateLegacyForm(
        spotlerActivateLegacyIntegration,
        setSpotlerActivateLegacy,
        featureFlags,
        checkMatchingAdvisorsForField,
        isChecking,
        advisorsWithMissingField
      ),
    },
  ]

  const enabledCdpFormList = formList.filter(
    (a) => a.enabled && a.category === "cdp"
  )
  const disabledCdpFormList = formList.filter(
    (a) => !a.enabled && a.category === "cdp"
  )
  const enabledAnalyticsFormList = formList.filter(
    (a) => a.enabled && a.category === "analytics"
  )
  const disabledAnalyticsFormList = formList.filter(
    (a) => !a.enabled && a.category === "analytics"
  )
  const customFormList = formList.filter((a) => a.category === "custom")

  return (
    <Form
      onSubmit={save}
      actions={
        !loading && (
          <Button isLoading={saving} primary disabled={!anyIntegrationsEnabled}>
            {saving ? <LoadingSpinner size="small" /> : "Save"}
          </Button>
        )
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <FormSection>
            <h2>{t("settings.integrationsSettings.title")}</h2>
          </FormSection>
          <FormSplit />
          {anyIntegrationsEnabled ? (
            <p>{t("settings.integrationsSettings.text")}</p>
          ) : (
            <div className="hints mb-2">
              <p>
                This feature is not part of your license. Contact us at{" "}
                <a
                  href="mailto:support@aiden.cx"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  support@aiden.cx
                </a>{" "}
                to upgrade your license.
              </p>
              <TutorialLink
                article="custom-integration"
                label="Gather customer insights with your CRM/CDP"
              />
            </div>
          )}
          <FormSection>
            <FieldGroup className="vertical-group">
              <h2 style={{ letterSpacing: "-0.025em" }} className="mb-0 mt-1">
                Analytics
              </h2>
              {enabledAnalyticsFormList.map(({ form }) => form)}
              {disabledAnalyticsFormList.map(({ form }) => form)}
              <h2 style={{ letterSpacing: "-0.025em" }} className="mb-0 mt-1">
                Customer data platform
              </h2>
              {enabledCdpFormList.map(({ form }) => form)}
              {disabledCdpFormList.map(({ form }) => form)}
              <h2 style={{ letterSpacing: "-0.025em" }} className="mb-0 mt-1">
                Custom integration
              </h2>
              {customFormList.map(({ form }) => form)}
            </FieldGroup>
          </FormSection>
          {error && <HelpText hasError>{t("errors.error")}</HelpText>}
        </>
      )}
    </Form>
  )
}

function googleAnalyticsForm(
  googleTagManagerIntegration: { enabled: boolean },
  setGoogleTagManager: (
    value: React.SetStateAction<{ enabled: boolean }>
  ) => void,
  featureFlags: FeatureFlags,
  googleAnalyticsIntegration: { enabled: boolean },
  setGoogleAnalytics: (
    value: React.SetStateAction<{ enabled: boolean }>
  ) => void
): ReactNode {
  return (
    <>
      <IntegrationOption
        enabled={googleTagManagerIntegration.enabled}
        onChange={(value: boolean) => {
          setGoogleTagManager({ enabled: value })
        }}
        integrationsEnabled={featureFlags.googleAnalyticsEnabled}
        icon={
          <img
            src={"/integrations/google_tag_manager.png"}
            alt={"Google Tag Manager logo"}
          />
        }
        integrationInfo={
          <>
            <p>
              Most websites use Google Tag Manager to connect to Google
              Analytics. This integration sends events from your Aiden apps to
              GTM. After enabling it, simply follow the tutorial to use the
              events in Google Analytics.
            </p>
            <TutorialLink
              article="google-tag-manager"
              label="Set up Google Tag Manager and Google Analytics"
            />
          </>
        }
      />

      <IntegrationOption
        enabled={googleAnalyticsIntegration.enabled}
        onChange={(value: boolean) => setGoogleAnalytics({ enabled: value })}
        integrationsEnabled={featureFlags.googleAnalyticsEnabled}
        icon={
          <img
            src={"/integrations/google_analytics.svg"}
            alt={"Google Analytics logo"}
          />
        }
        integrationInfo={
          <>
            <p>
              Some website use the gtag.js snippet to connect to Google
              Analytics. If you have the gtag.js snippet set up, enable this
              integration to send events from your Aiden apps directly to Google
              Analytics.
            </p>
            <TutorialLink
              article="google-analytics"
              label="Link with Google Analytics"
            />
          </>
        }
      />
    </>
  )
}

function customIntegrationForm(
  customIntegration: { enabled: boolean },
  setCustom: (value: React.SetStateAction<{ enabled: boolean }>) => void,
  featureFlags: FeatureFlags
): ReactNode {
  return (
    <IntegrationOption
      enabled={customIntegration.enabled}
      onChange={(value: boolean) => {
        setCustom({ enabled: value })
      }}
      integrationsEnabled={featureFlags.customIntegrationEnabled}
      icon={
        <div className="horizontal-group">
          <img src={"/integrations/custom.svg"} alt={"Custom integration"} />
          <strong style={{ fontSize: "1.25rem" }}>Build your own</strong>
        </div>
      }
      integrationInfo={
        <>
          <p>
            Use JavaScript to create your own custom integration to any
            platform. How? Learn more about it in the tutorial.
          </p>
          <TutorialLink
            article="custom-integration"
            label="Gather customer insights with your CRM/CDP"
          />
        </>
      }
    />
  )
}

function bloomreachForm(
  featureFlags: FeatureFlags,
  bloomReachIntegration: { enabled: boolean },
  setBloomReach: (value: React.SetStateAction<{ enabled: boolean }>) => void
): ReactNode | null {
  return (
    <IntegrationOption
      enabled={bloomReachIntegration.enabled}
      onChange={(value: boolean) => setBloomReach({ enabled: value })}
      integrationsEnabled={featureFlags.bloomreachEnabled}
      icon={
        <img src={"/integrations/bloomreach.svg"} alt={"Bloomreach logo"} />
      }
      integrationInfo={
        <>
          <p>
            Send data from your guided selling apps directly to Bloomreach.
            Events will be sent to Bloomreach Engagement if you have their
            snippet setup on your website.
          </p>
          <TutorialLink
            article="bloomreach"
            label="Send your data to Bloomreach"
          />
        </>
      }
    />
  )
}

function spotlerActivateLegacyForm(
  spotlerActivateLegacyIntegration: {
    enabled: boolean
    productIdField: string | null
  },
  setSpotlerActivateLegacy: (
    value: React.SetStateAction<{
      enabled: boolean
      productIdField: string | null
    }>
  ) => void,
  featureFlags: FeatureFlags,
  checkMatchingAdvisorsForField: (value: string | null) => void,
  isChecking: boolean,
  advisorsWithMissingField: FieldAvailability[] | undefined
): ReactNode {
  return (
    <IntegrationOption
      enabled={spotlerActivateLegacyIntegration.enabled}
      onChange={(value: boolean) => {
        setSpotlerActivateLegacy({
          ...spotlerActivateLegacyIntegration,
          enabled: value,
        })
      }}
      integrationsEnabled={featureFlags.spotlerActivateLegacyEnabled}
      icon={
        <strong>Spotler Activate (legacy)</strong>
      }
      integrationInfo={
        <>
          <div className="">
            <div className="toast no-animation warning">
              <p className="font-bold">
                  <span style={{marginRight: "0.25rem"}}>
                    <Warning/>
                  </span>
                This integration stops working on January 25, 2025
              </p>
              <p className="">
                Because Spotler Activate is changing the requirements for event names,
                it is necessary for you to start using the new version of the Spotler Activate integration.
                This new version ensures that the connection will continue to work after January 25, 2025.
                In the following article you can read more about switching to the new integration:
              </p>
              <p>&nbsp;</p>
              <TutorialLink
                article="spotler-activate-upgrade"
                label="How to upgrade to the new Spotler Activate integration"
              />
            </div>
          </div>
          <p>
            Send data from your guided selling apps directly to Spotler Activate
            (formerly Squeezely) for website personalisation and e-mail
            follow-ups. No need to configure anything, just make sure you have
            Spotler set up on your website!
          </p>
          <TutorialLink
            article="spotler-activate-legacy"
            label="Pair with Spotler Activate"
          />
        </>
      }
    >
      <div className="form-field">
        <label className="form-field__label">
          Product property to use as Spotler ID
        </label>

        <p className="subtle-text mt-0">
          Make sure your Aiden catalogues contain the product ID that is used by
          Spotler, then select that property here. Whenever a product event is
          triggered, Aiden will send the selected ID to Spotler for an instant
          match.
        </p>

        <div>
          <SingleSelect
            value={spotlerActivateLegacyIntegration.productIdField}
            onSelect={(value: string) => {
              checkMatchingAdvisorsForField(value)
              setSpotlerActivateLegacy({
                enabled: true,
                productIdField: value,
              })
            }}
            options={[
              {label: "id", value: null},
              {label: "sku", value: "sku"},
              {label: "gtin", value: "gtin"},
              {label: "ean", value: "ean" },
              { label: "mpn", value: "mpn" },
              { label: "squeezely_id", value: "squeezely_id" },
            ]}
          />
        </div>
        <p>
          {isChecking ? (
            <span>
              <LoadingSpinner />
            </span>
          ) : null}
          {advisorsWithMissingField?.length ? (
            <div className="">
              <div className="toast no-animation warning">
                <p className="font-bold">
                  <span style={{ marginRight: "0.25rem" }}>
                    <Warning />
                  </span>
                  The property '{spotlerActivateLegacyIntegration.productIdField}' is
                  not available in {advisorsWithMissingField?.length || 0} apps.
                </p>
                <p className="">
                  Using this property will mean that the Squeezely integration
                  will not function properly for those apps. Make sure the '
                  {spotlerActivateLegacyIntegration.productIdField}' property is
                  included in each of your apps' catalogues.
                </p>
              </div>
              <AdvisorsWithoutFieldOverview
                apps={advisorsWithMissingField || []}
                productIdField={spotlerActivateLegacyIntegration.productIdField}
              />
            </div>
          ) : null}
        </p>
      </div>
    </IntegrationOption>
  )
}

function spotlerActivateForm(
  spotlerActivateIntegration: {
    enabled: boolean
    productIdField: string | null
  },
  setSpotlerActivate: (
    value: React.SetStateAction<{
      enabled: boolean
      productIdField: string | null
    }>
  ) => void,
  featureFlags: FeatureFlags,
  checkMatchingAdvisorsForField: (value: string | null) => void,
  isChecking: boolean,
  advisorsWithMissingField: FieldAvailability[] | undefined
): ReactNode {
  return (
    <IntegrationOption
      enabled={spotlerActivateIntegration.enabled}
      onChange={(value: boolean) => {
        setSpotlerActivate({
          ...spotlerActivateIntegration,
          enabled: value,
        })
      }}
      integrationsEnabled={featureFlags.spotlerActivateEnabled}
      icon={
        <img
          src={"/integrations/spotler-activate.svg"}
          alt={"Spotler Activate logo"}
        />
      }
      integrationInfo={
        <>
          <p>
            Send data from your guided selling apps directly to Spotler Activate
            (formerly Squeezely) for website personalisation and e-mail
            follow-ups. No need to configure anything, just make sure you have
            Spotler set up on your website!
          </p>
          <TutorialLink
            article="spotler-activate"
            label="Pair with Spotler Activate"
          />
        </>
      }
    >
      <div className="form-field">
        <label className="form-field__label">
          Product property to use as Spotler ID
        </label>

        <p className="subtle-text mt-0">
          Make sure your Aiden catalogues contain the product ID that is used by
          Spotler, then select that property here. Whenever a product event is
          triggered, Aiden will send the selected ID to Spotler for an instant
          match.
        </p>

        <div>
          <SingleSelect
            value={spotlerActivateIntegration.productIdField}
            onSelect={(value: string) => {
              checkMatchingAdvisorsForField(value)
              setSpotlerActivate({
                enabled: true,
                productIdField: value,
              })
            }}
            options={[
              { label: "id", value: null },
              { label: "sku", value: "sku" },
              { label: "gtin", value: "gtin" },
              { label: "ean", value: "ean" },
              { label: "mpn", value: "mpn" },
              { label: "squeezely_id", value: "squeezely_id" },
            ]}
          />
        </div>
        <p>
          {isChecking ? (
            <span>
              <LoadingSpinner />
            </span>
          ) : null}
          {advisorsWithMissingField?.length ? (
            <div className="">
              <div className="toast no-animation warning">
                <p className="font-bold">
                  <span style={{ marginRight: "0.25rem" }}>
                    <Warning />
                  </span>
                  The property '{spotlerActivateIntegration.productIdField}' is
                  not available in {advisorsWithMissingField?.length || 0} apps.
                </p>
                <p className="">
                  Using this property will mean that the Squeezely integration
                  will not function properly for those apps. Make sure the '
                  {spotlerActivateIntegration.productIdField}' property is
                  included in each of your apps' catalogues.
                </p>
              </div>
              <AdvisorsWithoutFieldOverview
                apps={advisorsWithMissingField || []}
                productIdField={spotlerActivateIntegration.productIdField}
              />
            </div>
          ) : null}
        </p>
      </div>
    </IntegrationOption>
  )
}

function AdvisorsWithoutFieldOverview(props: {
  apps: FieldAvailability[]
  productIdField: string | null
}) {
  const { apps, productIdField } = props
  if (apps.length === 0 || productIdField === null) return null

  return (
    <div>
      <>
        <CollapseList items={apps} field={productIdField} max={5} />
      </>
    </div>
  )
}

function CollapseList(props: {
  items: FieldAvailability[]
  max: number
  field: string | undefined
}) {
  const { map } = useNavigation()
  const [isOpen, setOpen] = useState(false)
  // const items = props.items.sort((a, b) =>
  //   a.advisor.name.toLowerCase() > b.advisor.name.toLowerCase() ? 1 : -1
  // )
  const items = sortBy(props.items, (a) => [
    !a.isLive,
    a.advisor.name.toLowerCase(),
  ])
  const toShow = isOpen ? items : items.slice(0, props.max)
  return (
    <div>
      <table className="mb-1">
        <tr>
          <th className="text-align-left">
            Apps without '{props.field}' property
          </th>{" "}
          <th className="text-align-left">Status</th>
        </tr>
        {toShow.map((result) => (
          <tr>
            <td>
              <Link
                to={map.catalogueProductCatalogue(result.advisor.id)}
                className="link"
              >
                {result.advisor.name}
              </Link>
            </td>
            <td>
              <div className="horizontal-group has-centered-items has-small-gap">
                <span>{result.isLive ? "Live" : "Draft"}</span>
                {result.isLive ? (
                  <div className="status-indicator is-green"></div>
                ) : (
                  <div className="status-indicator"></div>
                )}
              </div>
            </td>
          </tr>
        ))}
      </table>

      {items.length > props.max ? (
        isOpen ? (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setOpen(!isOpen)}
          >
            Show less
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setOpen(!isOpen)}
          >
            Show {items.length - props.max} more
          </button>
        )
      ) : null}
    </div>
  )
}

const IntegrationOption: React.FC<{
  enabled: boolean
  integrationsEnabled: boolean
  icon: ReactNode
  integrationInfo: ReactNode
  onChange: (value: boolean) => void
}> = (props) => {
  return (
    <IntegrationCard isDisabled={!props.integrationsEnabled}>
      <div className="integration--toggle">
        {props.icon}
        <ToggleSwitch
          label={""}
          onChange={props.onChange}
          toggledOn={props.enabled}
          disabled={!props.integrationsEnabled}
        />
      </div>
      <div className="integration--info">{props.integrationInfo}</div>

      {props.children ? (
        <div className="settings-form">
          <div className="settings-form--divider" />
          <div
            className={`settings-form--row ${
              props.enabled ? "" : "is-disabled"
            }`}
          >
            {props.children}
          </div>
        </div>
      ) : null}
    </IntegrationCard>
  )
}

export default Integrations
