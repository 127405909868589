import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Show more",
  showLess: "Show less",
  bestMatch: "Best match",
  view: "View",
  customAction: "",
  notFound: "404: Not found",
  reset: "Start over",
  close: "Close",
  noAdviceTitle:
    "Currently there are no products available that fully match your preferences.",
  noAdviceMessage: "You can change your preferences, or try again later.",
  prev: "Previous",
  next: "Next",
  backToAdvice: "Back to advice",
  dontKnow: "Skip",
  email: "Email address",
  send: "Submit",
  emailError: "Please enter a valid email address",
  readMore: "Read more",
  selectAnAnswer: "Select an answer",
  answerNotListed: "My answer isn't listed",
  whyThisProductForMe: "Why this advice?",
  alternative: "alternative",
  alternatives: "alternatives",
  seeAlso: "Also see",
  badMatchIcon: "Bad match",
  goodMatchIcon: "Good match",
  perfectMatchIcon: "Perfect match",
  singleAnswerQuestionLegend: "Choose one option and continue.",
  multiAnswerQuestionLegend: "Choose one or more options",
  multiAnswerQuestionWithNeutralLegend: "Choose one or more options, or the bottom option if you have no preference.",
  manualDrilldownLegend: "Select one option. If there is a follow-up question, it will appear after choosing an answer. Use the 'Next' button to continue after answering all questions.",
  autoDrilldownLegend: "Select one option. If there is a follow-up question, it will appear after choosing an answer. Use the 'Next' button to continue after answering all questions. Is your answer not listed? Then select 'My answer isn't listed'.",
  loading: "Loading",
}

export default translations
