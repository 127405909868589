import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Visa mer",
  showLess: "Visa mindre",
  bestMatch: "Bästa matchningen",
  view: "Se",
  customAction: "",
  notFound: "404: ej Hittad",
  reset: "Börja om",
  close: "Stänga",
  noAdviceTitle:
    "För närvarande finns det inga tillgängliga produkter som helt matchar dina preferenser.",
  noAdviceMessage: "Du kan ändra dina inställningar eller försöka igen senare.",
  prev: "Föregående",
  next: "Nästa",
  backToAdvice: "Åter till råd",
  dontKnow: "Skippa",
  email: "E-postadress",
  send: "Skicka",
  emailError: "Vänligen ange en giltig e-postadress",
  readMore: "Läs mer",
  selectAnAnswer: "välj ett svar",
  answerNotListed: "Mitt svar finns inte med",
  whyThisProductForMe: "Varför detta råd?",
  alternative: "alternativ",
  alternatives: "alternativ",
  seeAlso: "Titta också på",
  badMatchIcon: "Dålig matchning",
  goodMatchIcon: "Bra matchning",
  perfectMatchIcon: "Perfekt matchning",
  singleAnswerQuestionLegend: "Välj ett alternativ och fortsätt.",
  multiAnswerQuestionLegend: "Välj ett eller flera alternativ.",
  multiAnswerQuestionWithNeutralLegend: "Välj ett eller flera alternativ, eller det nedersta alternativet om du inte har någon preferens.",
  manualDrilldownLegend: "Välj ett alternativ. Om det finns en följdfråga dyker den upp efter val av svar. Använd knappen 'Nästa' för att fortsätta efter att du har svarat på alla frågor.",
  autoDrilldownLegend: "Välj ett alternativ. Om det finns en följdfråga dyker den upp efter val av svar. Använd knappen 'Nästa' för att fortsätta efter att du har svarat på alla frågor. Är ditt svar inte listat? Välj sedan 'Mitt svar finns inte med'.",
  loading: "Laddar",
}

export default translations
