import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Mostrar más",
  showLess: "Muestra menos",
  bestMatch: "Mejor partido",
  view: "Mirar",
  customAction: "",
  notFound: "404: no encontrado",
  reset: "Volver a empezar",
  close: "Cerrar",
  noAdviceTitle:
    "Actualmente no hay productos disponibles que coincidan completamente con sus preferencias.",
  noAdviceMessage: "Ajuste sus preferencias o vuelva a intentarlo más tarde.",
  prev: "Previo",
  next: "Proximo",
  backToAdvice: "Volver al consejo",
  dontKnow: "Saltar",
  email: "Dirección de email",
  send: "Enviar",
  emailError: "Ingrese una dirección de correo válida",
  readMore: "Lee mas",
  selectAnAnswer: "Seleccione una respuesta",
  answerNotListed: "Mi respuesta no esta en la lista",
  whyThisProductForMe: "¿Por qué este consejo?",
  alternative: "alternativa",
  alternatives: "alternativas",
  seeAlso: "Mira también",
  badMatchIcon: "Coincidencia mala",
  goodMatchIcon: "Buena coincidencia",
  perfectMatchIcon: "Coincidencia perfecta",
  singleAnswerQuestionLegend: "Elija una opción y continúe.",
  multiAnswerQuestionLegend: "Elija una o más opciones.",
  multiAnswerQuestionWithNeutralLegend: "Elija una o más opciones, o la opción inferior si no tiene preferencia.",
  manualDrilldownLegend: "Seleccione una opción. Si hay una pregunta de seguimiento, aparecerá después de elegir una respuesta. Utilice el botón 'Proximo' para continuar después de responder todas las preguntas.",
  autoDrilldownLegend: "Seleccione una opción. Si hay una pregunta de seguimiento, aparecerá después de elegir una respuesta. Utilice el botón 'Proximo' para continuar después de responder todas las preguntas. ¿Su respuesta no aparece en la lista? Luego seleccione 'Mi respuesta no esta en la lista'.",
  loading: "Cargando",
}

export default translations
